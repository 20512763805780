import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				Privacy Policy | Asvata Olare Bar
			</title>
			<meta name={"description"} content={"Anyone can build a vacation, we build experiences"} />
			<meta property={"og:title"} content={"Privacy Policy | Asvata Olare Bar"} />
			<meta property={"og:description"} content={"Anyone can build a vacation, we build experiences"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/OGimage.png?v=2021-09-23T20:32:10.363Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/favicon.png?v=2021-09-23T20:33:18.314Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/270.png?v=2021-09-23T20:33:32.819Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" href="/index" />
			<Override slot="link1" overflow-y="hidden" />
		</Components.Header>
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" font="--headline1">
					Privacy Policy
				</Text>
				<Text margin="10px 0px 0px 0px" font="--lead">
					This Privacy Policy ("Policy") describes how asvata-olare.net ("we," "us," or "our") collects, uses, shares, and protects the personal information of visitors and users ("you" or "your") of the asvata-olare.net website and related services (collectively, the "Services").
					<br />
					<br />
					By using our Services, you agree to the practices described in this Policy. If you do not agree to this Policy, please do not use our Services.{"\n\n"}
				</Text>
			</Box>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				1. Information We Collect
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				We may collect the following types of information:
				<br />
				<br />
				1.1 Personal Information: We may collect personally identifiable information such as your name, email address, postal address, and other information you voluntarily provide when you interact with our Services.
				<br />
				<br />
				1.2 Usage Information: We may collect non-personal information about your interactions with our Services, including but not limited to your IP address, browser type, operating system, and pages visited. We may also use cookies and similar technologies to collect this information.{"\n\n\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				2. How We Use Your Information
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				We use your information for the following purposes:
				<br />
				<br />
				2.1 Providing and Improving Services: We use your information to provide, maintain, and improve our Services, including responding to your inquiries and requests.
				<br />
				<br />
				2.2 Analytics: We use analytics tools to analyze user trends and preferences, which helps us improve our Services.{"\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				3. Sharing Your Information
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following situations:
				<br />
				<br />
				3.1 Legal Requirements: We may disclose your information if required by law, regulation, legal process, or government request.
				<br />
				<br />
				3.2 Protection of Rights: We may disclose your information when we believe it is necessary to protect our rights, privacy, safety, or property, or to respond to an emergency that threatens the safety of any person.{"\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				4. Your Choices
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				You have the following choices regarding your personal information:
				<br />
				<br />
				4.1 Access and Correction: You may access and correct your personal information by contacting us at info@asvata-olare.net
				<br />
				<br />
				4.2 Opt-Out: You may opt-out of receiving promotional emails from us by following the instructions in those emails.{"\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				5. Security
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				We use reasonable administrative, technical, and physical measures to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				6. Children's Privacy
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				Our Services are not intended for individuals under the age of 13. If you believe that we have inadvertently collected information from a child under 13, please contact us, and we will promptly delete such information.{"\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				7. Changes to this Privacy Policy{"\n"}
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				We may update this Privacy Policy from time to time. Please review this Policy periodically to stay informed about our data practices.{"\n"}
				<br />
				{"\n\n\n\n"}
			</Text>
			<Text margin="10px 0px 10px 0px" font="--headline4">
				8. Contact Us{"\n"}
				<br />
				{"\n\n"}
			</Text>
			<Text margin="10px 0px 0px 0px" font="--lead">
				If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at info@asvata-olare.net{"\n\n\n"}
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});